import React, { useState, useEffect } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

type ScrollToTopButtonProps = {
  containerId?: string;
};

export const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({
  containerId,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (containerId) {
      const container = document.getElementById(containerId);
      if (container) {
        const containerScrollY = container.scrollTop;
        if (containerScrollY > 300) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    } else {
      // Check window scroll position if the container is not found
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  const scrollToTop = () => {
    if (containerId) {
      const container = document.getElementById(containerId);
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      // Scroll to the top of the window if the container is not found
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let container;
    if (containerId) {
      container = document.getElementById(containerId);
      if (container) {
        container.addEventListener("scroll", toggleVisibility);
      }
    }
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      if (containerId) {
        container.removeEventListener("scroll", toggleVisibility);
      }
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div
      role="button"
      aria-label="Scroll To Top"
      className={`fixed left-6 bottom-6 z-[2001] bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-xl cursor-pointer text-white p-3 rounded-full shadow-lg ${
        isVisible ? "visible" : "hidden"
      }`}
      onClick={scrollToTop}>
      <MdKeyboardDoubleArrowUp />
    </div>
  );
};

export default ScrollToTopButton;
